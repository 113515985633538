<template>
  <ul class="retailerList">
    <li v-for="(item, index) in items" :key="index">
      <div class="figure">
        <img :src="item.image" class="rounded" style="width: 150px; height: 100px" :style="item.sty"/>
      </div>
      <p class="figcaption">{{item.caption}}<br /></p>
    </li>
  </ul>
</template>

<script>
export default {
    props: ["items"],
    data() {
        return {};
    }
};
</script>

<style>
</style>