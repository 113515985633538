<template>
  <div>
    <help-popup ref="disclaimer">
      <p>
        <b>In what countries and languages will the game be available?</b
        ><br /><br />At our initial launch, LEGO Universe (the DVD game) will be
        available in the following languages: US English, UK English and German
        – in the following markets: the US, the UK, Central Europe (Germany,
        Austria and Switzerland), the Benelux (Holland, Belgium and Luxemburg)
        and the Nordics (Denmark, Norway and Sweden).<br /><br />LEGO Universe
        (the DVD game) will also be sold through LEGO Shop@Home in the following
        countries: Austria, Belgium, Canada, the Czech Republic, Denmark,
        Finland, France, Germany, Hungary, Ireland, Italy, Luxembourg, the
        Netherlands, Norway, Poland, Portugal, Spain, Sweden, Switzerland, the
        UK &amp; the US; i.e. all LEGO Shop@Home countries except for Australia,
        New Zealand and South Korea.<br /><br />Finally, LEGO Universe will be
        available for digital download from the following digital download
        retailers:<br /><br />- Transgaming / Game Tree Online<br />-
        GameStreamer<br />- GoGamer<br />- Metaboli<br />- Real Media<br />-
        Valve/Steam<br />- Nexway<br />- Gamers Gate<br />- Microsoft<br />-
        Ztorm<br />- IGN<br /><br />Note, however, that these digital download
        retailers will not be selling membership over and above the first month
        that is included with the purchase of the game. Additional membership
        will only be sold in the following markets: Austria, Belgium, Canada,
        the Czech Republic, Denmark, Finland, France, Germany, Hungary, Ireland,
        Italy, Luxembourg, the Netherlands, Norway, Poland, Portugal, Spain,
        Sweden, Switzerland, the UK &amp; the US; i.e. all LEGO Shop@Home
        markets except for Australia, New Zealand and South Korea.
      </p>
    </help-popup>

    <div class="column-row">
      <div class="column column-large first last">
        <div class="column-top"><h1>Gamebox</h1></div>
        <div class="column-content">
          <div class="column-round opaque">
            <b><i></i></b><i><b></b></i><em></em>
            <div class="column-round-body">
              <p>
                To enter and play LEGO Universe, you must purchase the game,
                which includes 1 month of membership. After that, you must renew
                your membership to continue playing. You can purchase a
                membership online at
                <router-link to="/parents/subscription">LEGO.com</router-link>,
                or you can buy a game card at one of the retailers listed under
                <router-link to="/parents/wheretobuy/232669"
                  >GAME CARDS</router-link
                >.

                <br /><br />This page lists all the retailers that sell the LEGO
                Universe Gamebox. Included in the Gamebox are an installation
                DVD and a story leaflet plus user guide to get you started.
                <br /><br /><a @click="showDisclaimer()" class="helpPopupLink"
                  >FIND OUT HERE</a
                >
                in which countries the LEGO Universe game and membership are
                sold.
              </p>
            </div>
          </div>

          <div class="column-round opaque column-round-flow">
            <b><i></i></b><i><b></b></i><em></em>
            <div class="column-round-body">
              <div class="tabBody">
                <div class="retailerBanner">
                  <router-link
                    to="/shop-55000"
                    id="ctl00_ContentPlaceHolderUniverse_RetailerBannerLink"
                  >
                    <img
                      id="ctl00_ContentPlaceHolderUniverse_RetailerBannerImage"
                      class="rounded"
                      src="@/assets/retailer-types/online-shop.png"
                      style="height: 114px; width: 644px"
                    />
                  </router-link>
                </div>

                <br />
                <h1>USA</h1>

                <retailer-list :items="items[0].retailers"></retailer-list>

                <h1>Canada</h1>

                <retailer-list :items="items[1].retailers"></retailer-list>

                <h1><br /><br />Great Britain</h1>

                <retailer-list :items="items[2].retailers"></retailer-list>

                <h1>Germany, Switzerland &amp; Austria</h1>

                <retailer-list :items="items[3].retailers"></retailer-list>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RetailerList from "@/components/parents/RetailerList.vue";
import HelpPopup from "@/components/HelpPopup.vue";

export default {
  data() {
    return {
      items: [
        {
          country: "USA",
          retailers: [
            {
              caption: "LEGO Brand Retail Store",
              image: require("@/assets/retailer-items/lego-brand.jpg"),
            },
            {
              caption: "LEGO Shop",
              image: require("@/assets/retailer-items/lego-brand.jpg"),
            },
            {
              // UniverseRetailerItems picF460D82D88601E24C899139173FB1085.jpg
            },
            {
              // UniverseRetailerItems picCF22C1E61244DCDE4BDAA77727B5A2E3.jpg
              image: require("@/assets/retailer-items/best-buy.jpg"),
            },
            {
              // UniverseRetailerItems pic345C09A7D1722A3FACC740C53BE32A0F.jpg
            },
            {
              // UniverseRetailerItems pic4B0F81C483F3B7277DA863C980EC5E84.jpg
            },
            {
              // UniverseRetailerItems pic6316B6F262E639659F18C30CAE11F6CF.jpg
              image: require("@/assets/retailer-items/game-stop.jpg"),
            },
            {
              // UniverseRetailerItems pic1EE6E32A529D6B094651E3A3BC2998F7.jpg
            },
            {
              // UniverseRetailerItems picBDEB7E2F1F489192096888E0817ECB0A.jpg
            },
            {
              // UniverseRetailerItems picADAF932764CD85553D8C774F033FA8D3.jpg
              image: require("@/assets/retailer-items/target.jpg"),
            },
            {
              // UniverseRetailerItems pic2EA7978E8E706F5EF52A26C1017FD023.jpg
              image: require("@/assets/retailer-items/toys-r-us.jpg"),
            },
            {
              // UniverseRetailerItems picCE2B8D2CFB006D17D982F1A3FA03D365.jpg
              image: require("@/assets/retailer-items/walmart.jpg"),
            },
          ],
        },
        {
          country: "Canada",
          retailers: [
            {
              // UniverseRetailerItems picA2B974D0F596F433080CF40911590B74.jpg
              image: require("@/assets/retailer-items/lego-brand.jpg"),
              caption: "LEGO Brand Retail Store",
            },
            {
              // UniverseRetailerItems picA2B974D0F596F433080CF40911590B74.jpg
              image: require("@/assets/retailer-items/lego-brand.jpg"),
              caption: "LEGO Shop",
            },
            {
              // UniverseRetailerItems picF460D82D88601E24C899139173FB1085.jpg
            },
            {
              // UniverseRetailerItems picCF22C1E61244DCDE4BDAA77727B5A2E3.jpg
              image: require("@/assets/retailer-items/best-buy.jpg")
            },
            {
              // UniverseRetailerItems pic345C09A7D1722A3FACC740C53BE32A0F.jpg"
            },
            {
              // UniverseRetailerItems pic4B0F81C483F3B7277DA863C980EC5E84.jpg
            },
            {
              // UniverseRetailerItems pic6316B6F262E639659F18C30CAE11F6CF.jpg
              image: require("@/assets/retailer-items/game-stop.jpg")
            },
            {
              // UniverseRetailerItems pic1EE6E32A529D6B094651E3A3BC2998F7.jpg
            },
            {
              // UniverseRetailerItems picBDEB7E2F1F489192096888E0817ECB0A.jpg
            }
          ]
        },
        {
          country: "Great Britain",
          retailers: [
            {
              // UniverseRetailerItems picA2B974D0F596F433080CF40911590B74.jpg
              image: require("@/assets/retailer-items/lego-brand.jpg"),
              caption: "LEGO Brand Retail Store",
            },
            {
              // UniverseRetailerItems picA2B974D0F596F433080CF40911590B74.jpg
              image: require("@/assets/retailer-items/lego-brand.jpg"),
              caption: "LEGO Shop",
            },
            {
              // UniverseRetailerItems picBE918C837F88EC88306FB117B5085C4B.jpg
            },
            {
              // UniverseRetailerItems picBD2A2328DD7F013260ADAB33ECFBFDC0.jpg
              image: require("@/assets/retailer-items/asda.jpg"),
            },
            {
              // UniverseRetailerItems picCDF709096E3999E30D517CD67B7FEA04.jpg
            },
            {
              // UniverseRetailerItems pic2EA7978E8E706F5EF52A26C1017FD023.jpg
              image: require("@/assets/retailer-items/toys-r-us.jpg"),
            },
            {
              // UniverseRetailerItems picA54DA63EACF7718D5DBDFA951D36217F.jpg"
            },
            {
              // UniverseRetailerItems picAC1C7420F1EB35BD9825398EE1AD0428.jpg
              image: require("@/assets/retailer-items/hmv.jpg"),
            },
            {
              // UniverseRetailerItems pic6755F661B912A05482BBB8EAA0D497A3.jpg
              image: require("@/assets/retailer-items/play-com.jpg"),
            },
            {
              // UniverseRetailerItems picFF73D8CA010488CAF8E227D6524CE693.jpg
              image: require("@/assets/retailer-items/tesco.jpg"),
            },
            {
              // UniverseRetailerItems picAFCCC36796D2C751F6827D99E0FADF9F.jpg
              image: require("@/assets/retailer-items/the-hut.jpg"),
            }
          ]
        },
        {
          country: "Germany, Switzerland & Austria",
          retailers: [
            {
              // UniverseRetailerItems picA2B974D0F596F433080CF40911590B74.jpg
              image: require("@/assets/retailer-items/lego-brand.jpg"),
              caption: "LEGO Brand Retail Store",
            },
            {
              // UniverseRetailerItems picA2B974D0F596F433080CF40911590B74.jpg
              image: require("@/assets/retailer-items/lego-brand.jpg"),
              caption: "LEGO Shop",
            },
            {
              // UniverseRetailerItems picC3B5FBE740178B7E9CF48A74CE1316C5.png
              image: require("@/assets/retailer-items/trusted.png"),
              sty: { width: "644px", height: "96px" },
            }
          ]
        }
      ],
    };
  },
  methods: {
    showDisclaimer() {
      this.$refs.disclaimer.open();
    }
  },
  components: {
    RetailerList,
    HelpPopup,
  },
};
</script>

<style>
</style>